import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { FaBlog } from "react-icons/fa" // Import ikon yang akan digunakan

import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/styles.scss"

const DefaultLayout = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StaticQuery
      query={graphql `
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              avatar
            }
          }
        }
      `}
      render={data => (
        <>
          <Header avatar={data.site.siteMetadata.avatar} />
          <div id="content">{children}</div>
          <Footer siteTitle={data.site.siteMetadata.title} />
          {/* Tambahkan tombol atau ikon untuk link ke blog */}
          <div className={`fixed bottom-8 right-8 transition-opacity duration-500 ${isScrolled ? 'opacity-100' : 'opacity-0'}`}>
            <a href="https://blog.syukrieseo.com" target="_blank" rel="noopener noreferrer" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center">
              <FaBlog className="mr-2" /> Kunjungi Blog Baru Saya
            </a>
          </div>
        </>
      )}
    />
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
